import type { BoxProps, ButtonProps, InputProps } from '@chakra-ui/react'
import { Box, Button, FormControl, FormErrorMessage, FormLabel, forwardRef, Input } from '@chakra-ui/react'
import type { FormProps as RemixFormProps } from '@remix-run/react'
import { Form as RemixForm, useActionData, useTransition } from '@remix-run/react'
import type { ForwardedRef, ReactElement } from 'react'
import { cloneElement } from 'react'

import type { ActionData } from '~/lib/form'

export const Form = forwardRef(function _Form(
    props: Omit<BoxProps, 'onChange'> & RemixFormProps,
    ref: ForwardedRef<HTMLFormElement> | null
) {
    return (
        <Box
            as={RemixForm}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
            {...props}
        >
            {props.children}
        </Box>
    )
})

interface FormFieldProps extends Omit<InputProps, 'defaultValue'> {
    name: string
    label: string
    input?: ReactElement
    defaultValue?: any
}

export function FormField({ label, input, ...props }: FormFieldProps) {
    const form = useActionData<ActionData<any>>()
    const clonedInput =
        input &&
        cloneElement(input, {
            defaultValue: form?.data?.[props.name] || '',
            id: props.id || props.name,
            ...props,
        })
    return (
        <FormControl isRequired={props.isRequired} isInvalid={!!form?.fieldErrors?.[props.name]}>
            <FormLabel htmlFor={props.name}>{label}</FormLabel>
            {clonedInput || <Input defaultValue={form?.data?.[props.name] || ''} id={props.name} {...props} />}
            <FormErrorMessage>{form?.fieldErrors?.[props.name]?.[0]}</FormErrorMessage>
        </FormControl>
    )
}

export function FormError() {
    const form = useActionData<ActionData<any>>()
    if (!form?.formError) return null
    return (
        <FormControl isInvalid={!!form?.formError}>
            <FormErrorMessage>{form?.formError}</FormErrorMessage>
        </FormControl>
    )
}
export function FormButton(props: ButtonProps) {
    const transition = useTransition()
    return (
        <Button
            type="submit"
            isLoading={transition.state === 'submitting'}
            isDisabled={transition.state === 'submitting'}
            {...props}
        />
    )
}
